import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import _Layout, { Main } from "../containers/layout"
import SEO from "../containers/seo"
import image from "../images/illustrations/message-sent.svg"

export default function MessageSentPage() {
  return (
    <Layout>
      <SEO
        title="Success!"
        meta={[
          {
            name: "robots",
            content: "noindex",
          },
        ]}
      />
      <MessageSentImage />
      <Heading>
        <span>Thank you!</span> Your message has been sent
      </Heading>
    </Layout>
  )
}

const Heading = styled.h2`
  font-size: ${rem("36px")};
  margin-bottom: 0;

  > span {
    color: ${props => props.theme.color.primary};
  }
`
const MessageSentImage = styled.img.attrs({ src: image, alt: "message sent" })`
  width: auto;
  max-width: 100%;
  margin-top: ${rem("72px")};
`
const Layout = styled(_Layout)`
  ${Main} {
    row-gap: 0;
    padding-bottom: ${rem("90px")};
    text-align: center;
  }

  ${Heading},
  ${MessageSentImage} {
    grid-column: main;
    justify-self: center;
  }
`
